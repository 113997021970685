<template>
  <b-card class="wh-space-bottom">

    <b-card-title class="font-weight-bolder">
      Demo Page
    </b-card-title>

    <b-container>

      <b-row class="mt-2"><h4>Mock Random</h4></b-row>
      <b-row>
        <b-button @click="request('/_demo/Random')" class="mr-1 mb-1" variant="primary">Random</b-button>
      </b-row>

      <b-row class="mt-2"><h4>SessionStorage</h4></b-row>
      <b-row>
        <b-button @click="handleSession('set')" class="mr-1 mb-1" variant="primary">SetSession</b-button>
        <b-button @click="handleSession('get')" class="mr-1 mb-1" variant="primary">GetSession</b-button>
      </b-row>

      <b-row class="mt-2"><h4>Router</h4></b-row>
      <b-row>
        <b-button @click="routerPush" class="mr-1 mb-1" variant="primary">Push</b-button>
      </b-row>

      <b-row class="mt-2"><h4>Prompt</h4></b-row>
      <b-row>
        <b-button @click="alert" class="mr-1 mb-1" variant="primary">SweetAlert</b-button>
        <b-button @click="confirm" class="mr-1 mb-1" variant="primary">Confirm</b-button>
        <b-button @click="toast()" class="mr-1 mb-1" variant="primary">ToastSuccess</b-button>
        <b-button @click="toast2()" class="mr-1 mb-1" variant="primary">ToastWarning
        </b-button>
      </b-row>

      <b-row class="mt-2"><h4>Requests (Old)</h4></b-row>
      <b-row>
        <b-button
          @click="requestOld('/company/GetCompanyList')"
          class="mr-1 mb-1" variant="primary"
        >
          GetCompanyList
        </b-button>
      </b-row>

      <b-row class="mb-1 mt-2"><h4>Requests (Old, Mock)</h4></b-row>
      <b-row><h5>Random</h5></b-row>
      <b-row>
        <b-button @click="requestOld('/_demo/Random_In_1s')" class="mr-1 mb-1" variant="primary">Random (Within 0.5s)
        </b-button>
        <b-button @click="requestOld('/_demo/Random_Immediately')" class="mr-1 mb-1" variant="primary">Random
          (Immediately)
        </b-button>
      </b-row>
      <b-row><h5>Status: 2XX (Success)</h5></b-row>
      <b-row>
        <b-button @click="requestOld('/_demo/Success')" class="mr-1 mb-1" variant="primary">Success</b-button>
      </b-row>
      <b-row><h5>Status: 2XX (With Error)</h5></b-row>
      <b-row>
        <b-button @click="requestOld('/_demo/UnknownError')" class="mr-1 mb-1" variant="primary">UnknownError</b-button>
        <b-button @click="requestOldWithSpecialPrompt('/_demo/ColumnLengthNotCorrect')" class="mr-1 mb-1"
                  variant="primary"
        >
          ColumnLengthNotCorrect
        </b-button>
      </b-row>
      <b-row><h5>Status: 1XX, 3XX, 4XX, 5XX</h5></b-row>
      <b-row>
        <b-button @click="requestOld('/_demo/Code_101')" class="mr-1 mb-1" variant="primary">101</b-button>
        <b-button @click="requestOld('/_demo/Code_300')" class="mr-1 mb-1" variant="primary">300</b-button>
        <b-button @click="requestOld('/_demo/Code_400')" class="mr-1 mb-1" variant="primary">400</b-button>
        <b-button @click="requestOld('/_demo/Code_401')" class="mr-1 mb-1" variant="primary">401</b-button>
        <b-button @click="requestOld('/_demo/Code_404')" class="mr-1 mb-1" variant="primary">404</b-button>
        <b-button @click="requestOld('/_demo/Code_500')" class="mr-1 mb-1" variant="primary">500</b-button>
      </b-row>
      <b-row><h5>Other Cases</h5></b-row>
      <b-row>
        <b-button @click="requestOld('/_demo/TimeOut')" class="mr-1 mb-1" variant="primary">TimeOut</b-button>
        <b-button @click="requestOld('/_demo/NetworkError')" class="mr-1 mb-1" variant="primary">NetworkError</b-button>
      </b-row>

      <b-row class="mt-2"><h4>Requests</h4></b-row>
      <b-row>
        <b-button
          @click="request('/company/GetCompanyList')"
          class="mr-1 mb-1" variant="primary"
        >
          GetCompanyList
        </b-button>
        <b-button
          @click="$requestWehealth({
            method: 'get',
            url: '/company/GetCompanyList',
            params: {
              companyID: 1,
              facilityID: 1,
            }
          })"
          class="mr-1 mb-1" variant="primary"
        >
          GetCompanyList (orig)
        </b-button>
      </b-row>

      <b-row class="mb-1 mt-2"><h4>Requests (Mock)</h4></b-row>
      <b-row><h5>Random</h5></b-row>
      <b-row>
        <b-button @click="request('/_demo/Random_In_1s')" class="mr-1 mb-1" variant="primary">Random (Within 0.5s)
        </b-button>
        <b-button @click="request('/_demo/Random_Immediately')" class="mr-1 mb-1" variant="primary">Random
          (Immediately)
        </b-button>
      </b-row>
      <b-row><h5>Status: 2XX (Success)</h5></b-row>
      <b-row>
        <b-button @click="request('/_demo/Success')" class="mr-1 mb-1" variant="primary">Success</b-button>
      </b-row>
      <b-row><h5>Status: 2XX (With Error)</h5></b-row>
      <b-row>
        <b-button @click="request('/_demo/UnknownError')" class="mr-1 mb-1" variant="primary">UnknownError</b-button>
        <b-button @click="requestWithSpecialPrompt('/_demo/ColumnLengthNotCorrect')" class="mr-1 mb-1"
                  variant="primary"
        >
          ColumnLengthNotCorrect
        </b-button>
      </b-row>
      <b-row><h5>Status: 1XX, 3XX, 4XX, 5XX</h5></b-row>
      <b-row>
        <b-button @click="request('/_demo/Code_101')" class="mr-1 mb-1" variant="primary">101</b-button>
        <b-button @click="request('/_demo/Code_300')" class="mr-1 mb-1" variant="primary">300</b-button>
        <b-button @click="request('/_demo/Code_400')" class="mr-1 mb-1" variant="primary">400</b-button>
        <b-button @click="request('/_demo/Code_401')" class="mr-1 mb-1" variant="primary">401</b-button>
        <b-button @click="request('/_demo/Code_404')" class="mr-1 mb-1" variant="primary">404</b-button>
        <b-button @click="request('/_demo/Code_500')" class="mr-1 mb-1" variant="primary">500</b-button>
      </b-row>
      <b-row><h5>Other Cases</h5></b-row>
      <b-row>
        <b-button @click="request('/_demo/TimeOut')" class="mr-1 mb-1" variant="primary">TimeOut</b-button>
        <b-button @click="request('/_demo/NetworkError')" class="mr-1 mb-1" variant="primary">NetworkError</b-button>
      </b-row>

    </b-container>

  </b-card>
</template>

<script>
import common from '@/common'
import ToastRows from '../../components/ToastRows'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import router from '@/router'

export default {
  data() {
    return {
      sharedWorker: {
        type: Object,
        default: null
      },
      connection: {
        type: Object,
        default: null
      },
      rangBellToast: {},
      toastIds: [],
      toastIdTemp: 0
    }
  },
  mounted() {
    window.currentRoute = this.$route
  },

  methods: {

    handleSession(method) {
      if (method === 'set') {
        const tmp = Math.random()
          .toString()
        console.log('set', tmp)
        sessionStorage.setItem('demo-item', tmp)
      } else {
        console.log(sessionStorage.getItem('demo-item'))
      }
    },

    routerPush() {
      this.$router.push({
        name: 'demoEdit2',
        params: { id: common.encrypt(1) }
      })
    },

    alert() {
      common.showAlert('Title', 'Content')
    },

    confirm() {
      common.showConfirm('Confirm Title')
    },

    ringAutomatically() {
      const that = this
      this.$toast({
        component: ToastRows,
        props: {
          variant: 'danger',
          title: '遊走通知',
          rows: [
            `地點：院舍北門`,
            `會員：張叁`,
            `時間：2022-11-25 15-25`
          ]
        },
        listeners: {
          confirm: () => {
            that.toastIds.reverse()
              .forEach((id) => {
                that.dismissToast(id)
              })
            that.$router.push({ name: 'geoFencingRecord' })
          }
        }
      }, {
        timeout: false,
        draggable: false,
        id: that.toastIdTemp,
      })
      that.toastIds.push(that.toastIdTemp)
      that.toastIdTemp += 1
    },
    ringCallBell(id) {
      const that = this
      this.rangBellToastId = this.$toast({
        component: ToastRows,
        props: {
          variant: 'danger',
          title: '緊急鍵通知'
        },
        listeners: {
          confirm: () => {
            console.log('Confirmed!');
            ['bell-1', 'bell-2'].forEach((id) => {
              that.dismissToast(id)
            })
            that.$router.push({ name: 'callBellRecord' })
          }
        }
      }, {
        timeout: false,
        draggable: false,
        id,
      })
    },
    dismissToast(id) {
      this.$toast.dismiss(id)
    },
    toast() {
      common.showToast('a', 'b')
    },
    toast2() {
      common.showToast({
        title: 'a',
        text: 'b',
        variant: 'warning'
      })
    },
    requestOld(api) {
      common.apiGetData(api)
        .then((res) => {
          if (res?.return_code === 0) {
            common.showToast('Success')
          }
        })
    },
    request(api) {
      this.$requestWehealth.get(api)
        .then((res) => {
          if (res?.return_code === 0) {
            common.showToast('Success')
          }
        })
    },
    requestOldWithSpecialPrompt(api) {
      common.apiGetData(api, true, false)
        .then((res) => {
          if (res?.return_code === 0) {
            common.showToast('Success')
          } else {
            let errorRowMsg = '',
              errorContentMsg = ''
            if (typeof res.data['errorRow'] === 'number' && res.data['errorRow'] > 0) {
              errorRowMsg = this.$t('batchupload.errorRowMsg')
                .replace('{rowNum}', res.data['errorRow']) + ' '
            }
            if (typeof res.data['errorContent'] === 'string' && res.data['errorContent'] !== '') {
              // 返回的 errorContent 會是excel表中出錯的cell的內容
              errorContentMsg = ': ' + res.data['errorContent']
            }
            common.showAlert(errorRowMsg + this.$t('error.' + res.result_msg) + errorContentMsg)
          }
        })
    },
    requestWithSpecialPrompt(api) {
      this.$requestWehealth({
        // whShowOverlay: false,
        whShowErrorMsgWhen200: false,
        url: api,
      })
        .then(res => {
          if (res?.return_code === 0) {
            common.showToast('Success')
          } else {
            let errorRowMsg = '',
              errorContentMsg = ''
            if (typeof res.data['errorRow'] === 'number' && res.data['errorRow'] > 0) {
              errorRowMsg = this.$t('batchupload.errorRowMsg')
                .replace('{rowNum}', res.data['errorRow']) + ' '
            }
            if (typeof res.data['errorContent'] === 'string' && res.data['errorContent'] !== '') {
              // 返回的 errorContent 會是excel表中出錯的cell的內容
              errorContentMsg = ': ' + res.data['errorContent']
            }
            common.showAlert(errorRowMsg + this.$t('error.' + res.result_msg) + errorContentMsg)
          }
        })
    },
  },
}
</script>
